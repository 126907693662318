import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Box from "../../../components/box"
import Breadcrumb from "../../../components/breadcrumb"
import Grid from "../../../components/grid"
import Heading from "../../../components/heading"
import HoelderlinLiebtNavigation from "../../../content/hoelderlin-liebt/navigation"
import Image from "../../../components/image"
import ImageFrame from "../../../components/image-frame"
import Inline from "../../../components/inline"
import Layout from "../../../layouts/default"
import PageTitle from "../../../components/page-title"
import Paragraph from "../../../components/paragraph"
import SectionSpacing from "../../../components/section-spacing"
import Seo from "../../../components/seo"
import Stack from "../../../components/stack"
import VideoPlayer from "../../../components/video-player"

const HoelderlinLiebtWoerter = () => {
  const data = useStaticQuery(
    graphql`
      {
        ogImage: file(relativePath: { eq: "og_images/hoelderlin-liebt.jpg" }) {
          id
          childImageSharp {
            gatsbyImageData(width: 1200, height: 600, layout: FIXED)
          }
        }
        titelbild: file(
          relativePath: { eq: "hoelderlin-liebt/einleitung-woerter.jpg" }
        ) {
          ...mediumImage
        }
        ballTag: file(relativePath: { eq: "hoelderlin-liebt/ball-tag.jpg" }) {
          childImageSharp {
            gatsbyImageData(width: 384, height: 384, layout: CONSTRAINED)
          }
        }
        ballGott: file(relativePath: { eq: "hoelderlin-liebt/ball-gott.jpg" }) {
          childImageSharp {
            gatsbyImageData(width: 384, height: 384, layout: CONSTRAINED)
          }
        }
        ballHeilig: file(
          relativePath: { eq: "hoelderlin-liebt/ball-heilig.jpg" }
        ) {
          childImageSharp {
            gatsbyImageData(width: 384, height: 384, layout: CONSTRAINED)
          }
        }
        ballHerz: file(relativePath: { eq: "hoelderlin-liebt/ball-herz.jpg" }) {
          childImageSharp {
            gatsbyImageData(width: 384, height: 384, layout: CONSTRAINED)
          }
        }
        ballMensch: file(
          relativePath: { eq: "hoelderlin-liebt/ball-mensch.jpg" }
        ) {
          childImageSharp {
            gatsbyImageData(width: 384, height: 384, layout: CONSTRAINED)
          }
        }
        ballSchoen: file(
          relativePath: { eq: "hoelderlin-liebt/ball-schoen.jpg" }
        ) {
          childImageSharp {
            gatsbyImageData(width: 384, height: 384, layout: CONSTRAINED)
          }
        }
        ballStill: file(
          relativePath: { eq: "hoelderlin-liebt/ball-still.jpg" }
        ) {
          childImageSharp {
            gatsbyImageData(width: 384, height: 384, layout: CONSTRAINED)
          }
        }
        ballSuess: file(
          relativePath: { eq: "hoelderlin-liebt/ball-suess.jpg" }
        ) {
          childImageSharp {
            gatsbyImageData(width: 384, height: 384, layout: CONSTRAINED)
          }
        }
        poster: file(
          relativePath: { eq: "hoelderlin-liebt/poster-baellebad.png" }
        ) {
          ...largeImage
        }
        wortWandern: file(
          relativePath: { eq: "hoelderlin-liebt/wort-wandern.jpg" }
        ) {
          ...largeImage
        }
        wortSommer: file(
          relativePath: { eq: "hoelderlin-liebt/wort-sommer.jpg" }
        ) {
          ...largeImage
        }
        wortWinter: file(
          relativePath: { eq: "hoelderlin-liebt/wort-winter.jpg" }
        ) {
          ...largeImage
        }
      }
    `
  )

  return (
    <Layout backdrop="hoelderlin-liebt">
      <Seo
        title="Hölderlin liebt die Wörter"
        description="Die letzten 36 Jahre seines Lebens verbringt Hölderlin bei der Familie Zimmer in Tübingen."
        image={data.ogImage.childImageSharp.gatsbyImageData}
      />
      <SectionSpacing>
        <Stack>
          <Breadcrumb
            items={[
              {
                title: "Digital",
                link: "/digital",
              },
              {
                title: "Sonderausstellungen",
                link: "/sonderausstellungen",
              },
              {
                title: "Hölderlin liebt",
                link: "/sonderausstellungen/hoelderlin-liebt",
              },
              {
                title: "… die Wörter",
                link: "/sonderausstellungen/hoelderlin-liebt/woerter",
              },
            ]}
          />
          <PageTitle>Hölderlin liebt die Wörter</PageTitle>
          <Grid columns={[1, 2]} alignY="center">
            <Paragraph dropcap={true}>
              Wie schreibe ich ein gutes Gedicht? <br />
              Das fragt sich Hölderlin sein Leben lang. <br />
              Dafür sucht er sich seine Wörter ganz genau aus. <br />
              Er sammelt sie. <br />
              Er spricht sie laut vor sich hin. <br />
              Er mag ihren Klang.
            </Paragraph>
            <ImageFrame>
              <Image
                image={data.titelbild.childImageSharp.gatsbyImageData}
                attribution="Jette von Bodecker"
                alt="Eine Illustration zeigt Hölderlin. Er jongliert mit Bällen."
              />
            </ImageFrame>
          </Grid>
        </Stack>

        <Stack>
          <Heading as="h2" level={3}>
            Hölderlins Lieblingswörter
          </Heading>
          <Paragraph>
            Diese Wörter mag Hölderlin besonders gern. <br />
            Er verwendet sie immer wieder in seinen Gedichten.
          </Paragraph>
          <Inline alignY="center" alignX="center">
            <Box width={48} height={48} borderRadius="9999em" overflow="hidden">
              <Image
                image={data.ballTag.childImageSharp.gatsbyImageData}
                alt="Tag"
              />
            </Box>
            <Box width={48} height={48} borderRadius="9999em" overflow="hidden">
              <Image
                image={data.ballGott.childImageSharp.gatsbyImageData}
                alt="Gott"
              />
            </Box>
            <Box width={48} height={48} borderRadius="9999em" overflow="hidden">
              <Image
                image={data.ballHeilig.childImageSharp.gatsbyImageData}
                alt="Heilig"
              />
            </Box>
            <Box width={48} height={48} borderRadius="9999em" overflow="hidden">
              <Image
                image={data.ballHerz.childImageSharp.gatsbyImageData}
                alt="Herz"
              />
            </Box>
            <Box width={48} height={48} borderRadius="9999em" overflow="hidden">
              <Image
                image={data.ballMensch.childImageSharp.gatsbyImageData}
                alt="Mensch"
              />
            </Box>
            <Box width={48} height={48} borderRadius="9999em" overflow="hidden">
              <Image
                image={data.ballSchoen.childImageSharp.gatsbyImageData}
                alt="Schön"
              />
            </Box>
            <Box width={48} height={48} borderRadius="9999em" overflow="hidden">
              <Image
                image={data.ballStill.childImageSharp.gatsbyImageData}
                alt="Still"
              />
            </Box>
            <Box width={48} height={48} borderRadius="9999em" overflow="hidden">
              <Image
                image={data.ballSuess.childImageSharp.gatsbyImageData}
                alt="Süß"
              />
            </Box>
          </Inline>
        </Stack>

        <Grid columns={[1, 2]} alignY="center">
          <Paragraph>
            In der Ausstellung im Hölderlinturm gibt es ein Bällebad. <br />
            Aus dem Bällebad können Sie ihr Lieblingswort angeln.
          </Paragraph>
          <VideoPlayer src="hoelderlin-liebt/baellebad" poster={data.poster} />
        </Grid>

        <Stack>
          <Heading as="h2" level={3}>
            Hölderlins Lieblingswörter in Gebärdensprache
          </Heading>
          <Grid columns={[1, 2, 3]} space="px">
            <Image
              image={data.wortWandern.childImageSharp.gatsbyImageData}
              alt="Eine Illustration von einem Mann mit Wanderstab zeigt die Gebärde für Wandern."
            />
            <Image
              image={data.wortSommer.childImageSharp.gatsbyImageData}
              alt="Eine Illustration von einer Frau zeigt die Gebärde für Sommer: Sie wischt sich mit der Hand den Schweiß von der Stirn."
            />
            <Image
              image={data.wortWinter.childImageSharp.gatsbyImageData}
              alt="Eine Illustration von einem frierenden Mann zeigt die Gebärde für Winter: Seine Schultern sind hochgezogen. Er bewegt die Fäuste von oben nach unten vor seiner Brust."
            />
          </Grid>
        </Stack>

        <HoelderlinLiebtNavigation />
      </SectionSpacing>
    </Layout>
  )
}

export default HoelderlinLiebtWoerter
